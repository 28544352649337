.lia-meta {
  display: flex;
  flex-direction: row;

  .lia-info {
    font-size: var(--lia-bs-font-size-sm);
    color: var(--lia-bs-body-color);
    font-weight: var(--lia-bs-font-weight-base);
  }
  .lia-count {
    margin-right: 3px;
  }
}
